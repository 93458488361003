'use strict'

class LiteAccountsClass {
  
  static localStorageKey = 'dylite';
  
  saveLite() {
    localStorage.setItem(LiteAccountsClass.localStorageKey, 'y');
  }
  
  haveLite() {
    var x = localStorage.getItem(LiteAccountsClass.localStorageKey);
    return (x != null)
  }
  
  clearLite() {
    localStorage.removeItem(LiteAccountsClass.localStorageKey);
  }
  
  ensure(path, key) {
    if (!this.haveLite()) {
      this.redirForLite(path, key);
      return false;
    } else {
      return true;
    }
  }
  
  redirForLite(path, key) {
    // key: ff is First Foods
    console.log("would redir and return to", path);
    var url = `/en/account/lite?return=${path}&key=${key}`;
    console.log("would now send to", url);
    window.location = url;
    
  }
  
}

class AccountsClass {

  
  static localStorageKey = 'dywl';
  
  
  saveLoginKey(key) {
    localStorage.setItem(AccountsClass.localStorageKey, key)
  }
  
  getLoginKey() {
    return localStorage.getItem(AccountsClass.localStorageKey)
  }
  
  clearLoginKey() {
    localStorage.removeItem(AccountsClass.localStorageKey)
  }
  
  associateYumSessionPost(path, login_key) {
    var document = window.document;
    var f = document.createElement('form');
    f.action = path;
    f.method = 'POST';
    var input_lk = document.createElement('input');
    input_lk.name = 'login_key';
    input_lk.value = login_key;
    f.appendChild(input_lk);

    var au = document.createElement('input');
    au.name = document.querySelector('meta[name=csrf-param]').content;
    au.value = document.querySelector('meta[name=csrf-token]').content;
    f.appendChild(au);

    document.body.appendChild(f);
    f.submit();
  }
  
  
}

var Accounts = new AccountsClass()

var LiteAccounts = new LiteAccountsClass()
export {Accounts}
export {LiteAccounts}

