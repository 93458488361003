'use strict'

import {Accounts} from '../Accounts/Accounts'
import {LiteAccounts} from '../Accounts/Accounts'


class YumWindowService {

  accounts() {
    var a = Accounts;
    console.log("YumWindowservice returning accounts", a);
    return a;
  }
  liteAccounts() {
    var la = LiteAccounts;
    return la;
  }
  
  

}

if (!window.yumWindow) {
  window.yumWindow = new YumWindowService()
}

var YumWindow = window.yumWindow;

export {YumWindow}


