debug = false

//
// var doGear = () => {
//   var c = parseInt(Math.random() * 3) + 1;
//   if (debug) {
//     console.log("Doing", c)
//   }
//   var j = document.querySelector('.promo-animate-gears .svg-gear:nth-child(' + c + ') svg');
//   j.classList.add('rotate-once');
//   setTimeout(() => {
//     j.classList.remove('rotate-once');
//     var interval = (Math.random() * 2000) + 200;
//     setTimeout(doGear, interval);
//   }, 2000)
// }

var gearState = {1: {}, 2: {}, 3: {}};

var doGear2 = () => {
  var c = parseInt(Math.random() * 3) + 1;
  if (debug) {
    console.log("Doing", c)  
  }
  if (! gearState[c].rotating) {
    gearState[c].rotating = true;
    var j = document.querySelector('.promo-animate-gears .svg-gear:nth-child(' + c + ') svg');
    
    var rotateTime = 2000;
    var cname = 'rotate-once';
    if (parseInt(Math.random() * 10) == 2) {
      cname = 'rotate-once-reverse';
      rotateTime = 1000;
    }
    j.classList.add(cname);
    setTimeout(() => {
      gearState[c].rotating = false;
      j.classList.remove(cname);
    }, rotateTime)
  }
  // now trigger another one
  var interval = (Math.random() * 1500) + 200
  setTimeout(doGear2, interval)
}
    

var startup = () => {
  if (document.querySelectorAll('.promo-animate-gears').length > 0) {
    doGear2()
  }
}

window.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(startup, 2000);
})




