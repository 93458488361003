
doFaders = function() {
  faders = document.querySelectorAll('.yum-fader');
  if (faders.length == 0) {
    return
  }
  console.log('faders', faders);
  idx = -1;
  fader = faders[0];
  count = fader.children.length;
  console.log({idx: idx, count: count})
    
  callback = function() {
    // console.log("running with index idx")
    idx += 1;
    if (idx >= count) {
      idx = 0
    }
    // console.log("Adding idx is", idx)
      
    fader.children[idx].classList.add('fade-now');
    setTimeout(function() {
      callback()
    }, 2500);
      
    a = {
      removeidx: idx,
      removeMe: function() {
        // console.log("Removal idx is", this.removeidx)
        fader.children[this.removeidx].classList.remove('fade-now');
      }
        
    }
      
      
    setTimeout(a.removeMe.bind(a), 3500);
      
  }
  callback()
}
window.addEventListener('DOMContentLoaded', (event) => {
  doFaders();
});

